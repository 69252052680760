<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    @change="onCallback($event)"
    :loading="loading"
    v-bind="$attrs"
    :search-input="search"
    @update:search-input="goSearch"
  >
    <template #append-item>
      <div class="d-flex justify-center align-center">
        <div v-intersect="onIntersect">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
            :size="20"
            :width="2"
          ></v-progress-circular>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>
<script>
import { debounce } from "lodash";

export default {
  props: {
    value: [Number, String],
    api: [Function],
  },
  data: () => ({
    search: "",
    loading: false,
    items: [],
    params: {
      page: 1,
      last_page: 1,
      perPage: 100,
      default_value: "",
    },
  }),
  watch: {
   
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("change", value);
      },
    },
  },
  created() {
    if (this.value) {
      this.default_value = this.value;
    }
    this.fetchData();
  },
  methods: {
    goSearch(val) {
      this.search = val;
      debounce(() => {
        this.params.page = 1;
        this.params.last_page = 1;
        this.fetchData();
      }, 500);
    },
    async fetchData(isLoadmore = false) {
      this.loading = true;
      try {
        let res = await this.api({ ...this.params, search: this.search });
        this.items = isLoadmore ? [...this.items, ...res.data] : res.data;
        this.params.page = res.current_page;
        this.params.last_page = res.last_page;
      } finally {
        this.loading = false;
      }
    },
    onIntersect(entries, observer, isIntersecting) {
      if (
        isIntersecting &&
        this.params.page < this.params.last_page &&
        !this.loading
      ) {
        this.params.page++;
        this.fetchData(true);
      }
    },
    onCallback(e) {
      let item = this.items.find((item) => {
        return item.id == e;
      });
      this.$emit("change", item);
    },
  },
};
</script>
