<template>
  <v-dialog v-model="dialogSign" :width="width">
    <v-card>
      <v-card-title class="text-h5 center grey lighten-2">
        Tạo chữ ký
        <v-spacer></v-spacer>
        <v-btn class="ml-3" raised text @click="close()">
          <v-icon style="margin-bottom: 2px; margin-right: 4;" small>
            mdi-close-circle
          </v-icon>
          Đóng
        </v-btn>
      </v-card-title>
      <div class="container">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="400px"
          ref="signaturePad"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
            ...options,
          }"
        />
      </div>
      <div class="d-flex justify-center align-center mt-1 mb-4">
        <div class="mr-3">Màu:</div>
        <div class="d-flex align-center justify-between ">
          <v-btn
            class="mr-2"
            v-for="(color, index) in colors"
            :key="`color-${index}`"
            fab
            :color="color"
            height="18"
            width="18"
            @click="changeColor(color)"
          ></v-btn>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex align-center justify-center py-4 ">
        <v-btn
          class="mr-3"
          depressed
          elevation="2"
          raised
          color="primary"
          @click="undo()"
        >
          Hoàn tác
        </v-btn>

        <v-btn
          class="mr-3"
          depressed
          elevation="2"
          raised
          color="warning"
          @click="clear()"
        >
          Xóa hết
        </v-btn>
        <v-btn class="mr-3" color="success" depressed @click="save()">
          Xác nhận ký phê duyệt
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "400px",
    },
  },
  data: () => ({
    dialogSign: false,
    options: {
      penColor: "#c0f",
    },
    colors: ["#b63a1b", "#1bb64f", "#1f22d3"],
  }),
  methods: {
    open(id) {
      this.dialogSign = true;
    },
    close() {
      this.clear();
      this.options.penColor = "#c0f";
      this.dialogSign = false;
    },
    changeColor(color) {
      this.options.penColor = color;
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        let signFile = this.convertBase64ToFileOject(data, "signDigital");
        this.$emit("save-sign", {
          signFile: signFile,
          clearSign: true,
        });
        this.dialogSign = false;
      } else {
        this.$emit("missing-sign", "Cần có chữ ký trước khi phê duyệt");
      }
    },
    convertBase64ToFileOject(data, filename) {
      let base64 = data.split(",")[1];
      let mine = data
        .split(",")[0]
        .split(";")[0]
        .split(":")[1];
      let bstr = atob(base64);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
      }
      return new File([u8arr], filename, { type: mine });
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
